.query-builder {
  padding: @space;
  flex-direction: column;
  display: flex;
  height: 100%;
  box-sizing: border-box;
  
  .expressions {
    overflow: auto;
    border: solid 1px @borderColor;    
    width: 100%;    
  }

  .add-group {
    width: 100%;    
  }

  .or-label {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .iconed-action {
    padding: @spaceMini @spaceHalf;
    cursor: pointer;
    opacity: .9;
    &:hover {
      opacity: 1;
    }
  }


}
