/**
 * importer.less
 *
 * By default, new Sails projects are configured to compile this file
 * from LESS to CSS.  Unlike CSS files, LESS files are not compiled and
 * included automatically unless they are imported below.
 *
 * The LESS files imported below are compiled and included in the order
 * they are listed.  Mixins, variables, etc. should be imported first
 * so that they can be accessed by subsequent LESS stylesheets.
 *
 * (Just like the rest of the asset pipeline bundled in Sails, you can
 * always omit, customize, or replace this behavior with SASS, SCSS,
 * or any other Grunt tasks you like.)
 */

/**
 * Импортируемые файлы
 * */

@import 'reset.less';

@import "@fortawesome/fontawesome-free/less/solid.less";
@import "@fortawesome/fontawesome-free/less/fontawesome.less";

@import 'base.less';
@import 'mixins.less';
@import 'widgets.less';

@import 'header.less';
@import 'sidebar.less';
@import 'content.less';
@import 'grid.less';
@import 'footer.less';

@import 'modal.less';
@import 'notifications.less';
@import 'contextMenu.less';
@import 'tabView.less';

@import 'forms.less';
@import 'referenceView.less';
@import 'queryBuilder.less';
@import 'rtl.less';
@import 'about.less';
@import 'map.less';
@import 'slider.less';