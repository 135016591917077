@keyframes upscale {
  from { 
    opacity: 0;
    transform: scale(0.95);
  }
  to { 
    opacity: 1;
    transform: scale(1);
  }
}

.colorize() {
  &-success {
    background: #658622AA;
    @supports (-moz-appearance:none) {
      background: #658622;
    }
  }

  &-error {
    background: #ff3200aa;
    @supports (-moz-appearance:none) {
      background: #ff3200;
    }
  }

  &-warning {
    background: #bc742Edd;
    @supports (-moz-appearance:none) {
      background: #bc742E;
    }
  }

  &-black {
    background: #000A;
    @supports (-moz-appearance:none) {
      background: #000;
    }
  }

  &-default {
    background: #222132aa;
    @supports (-moz-appearance:none) {
      background: #222132;
    }
  }
}

.notification-prefs {

  h4, .inline-action, .icon {
    color: @colorTextOpposite;
  }

  .muted {
    color: fade(@colorTextOpposite, 50%)
  }

  [type=checkbox]:checked + span {
    background-color: @secondary;
  }

  label span.checkbox, label span.radio {
    outline: solid 1px orange;
  }

  .notifications-journal-item {
    background: #FFF2;
    padding: @spaceHalf;
    margin-bottom: @spaceMini;
    border-radius: @spaceMini;
    .details {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .type {
    .colorize();
  }
}

#flashes-region {
  position: absolute;
  width: 100%;

    .all-notifications {
      position: absolute;
      transition: right .5s ease-in;
            
      right: @spaceHalf;
      top: 0;
      
      z-index: 301;
      width: 28%;
      min-width: 345px;
      max-width: 345px;
      max-height: 35vh;
      overflow: hidden;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      @media @smartphones {
        right: unset;
        top: 0;
        width: 100%;
        min-width: unset;
        max-width: unset;        
      }

      &.compact {
        right: -385px;
        
        div.alert:hover {
          cursor: pointer;
          position: relative;
          transition: right .5s ease;
          right: 350px !important;
          opacity: .98;
        }
      }
    }

    .button {
      .colorize();
    }


    .alert {
      will-change: opacity, transform;
      animation: upscale 0.25s ease-in;

      max-width: 100%;
      .colorize();
      .noselect;
      opacity: .98;
      border-radius: 2px;
      margin-top: @spaceHalf;
      border: none;
      display: flex;
      color: @colorTextOpposite;
      transition: opacity .25s ease;
      box-shadow: 3px 3px 0px rgba(0,0,0,.2), 0px 0px 1px @colorTextOpposite;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
              
      @media @smartphones {
        margin: @spaceHalf;
      }

      &:hover {
        opacity: .7;
      }

      .bold {
        // font-weight: bold
        font-family: 'Accented';
      }
     
      button {
        font-size: 1.25em;
        height: 1em;
        float: right;
        background-color: transparent;
        color: white;
        cursor: pointer;
        border: none;
        box-shadow: none;
        overflow: visible;
      }

      .buttons {
        display: flex;
        .action-button {
          width: 100%;
          margin: @space @spaceMini 0 0;
          padding: 0 @spaceHalf;
          border-radius: 4px;
          overflow: hidden;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
      }
      
      .icon-container {
        .notification-icon {
          width: @space*2;
          height: @space*2;
          font-size: @spaceHalf*3;
          margin-inline-start: @space;
          margin-top: @space;
          margin-bottom: @spaceHalf+@spaceMini;
          color: @colorTextOpposite;
        }
      }

      div.message {
        padding: @space;
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;   
        
        .details {
          font-family: 'Normal';
          white-space: pre-line;
          max-height: 400px;
          overflow: auto;          
        }

        .label {
          margin-top: @space;
        }

        .progress {
          padding: 1px;
          width: 100%;
          border: solid @borderSize @colorTextOpposite;
          margin-top: @spaceHalf;

          .inner-line {            
            .transition(width 0.5s);
            background: white;
            width: 5%;
            height: .5em;
          }
        }
      }
    }

    .dont-show-again {
      padding-top: 1rem;
      text-decoration: underline dashed;
      text-decoration-skip-ink: none;
      cursor: pointer;
      font-size: .9em;
      text-align: right;
    }
}

.rtl {
  #flashes-region {
    .all-notifications {
      left: @spaceHalf;
      right: unset
    }
  }
}