.react-contextmenu {
  background-color: @background;  
  border: solid @borderColor @borderSize;
  color: @colorText;  

  min-width: 160px;
  outline: none;
  opacity: 0;
 //  padding: @spaceMicro;
  pointer-events: none;
  text-align: left;
  transition: opacity 150ms ease !important;
  z-index: 8000;
  box-shadow: 5px 5px 1px rgba(0, 0, 0, 0.1);
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {   
  cursor: pointer;
  padding: @spaceMini @spaceHalf;
  line-height: 1.5;
  text-align: inherit;
  white-space: nowrap;
  user-select: none;
  display: flex;
  align-items: center;

  .icon {
    width: 1rem;
    margin-inline-end: 0.5rem;
  }

  .contents {
    .w100();
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    span {
      white-space: nowrap;
    }
    
    .name {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .muted {
      padding-left: @space;
      text-transform: uppercase;
    }
  }
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: @colorTextOpposite;
  background-color: @backgroundPrimaryButtonHovered;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0,0,0,.15);
  color: #878a8c;

  &> .icon {
    color: #878a8c;
  }
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0,0,0,.15);
  height: 0;
  padding: 0;
  margin: 0;
  cursor: inherit;
}

.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0,0,0,.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
padding: 0;
}

 
.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
  content: "▶";
  display: inline-block;
  position: absolute;
  right: 7px;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}