// QC: FAILED
@rowHeigth: 1.5em;

.grid {
  height: 100%;
  width: 100%;
  display: flex;
  overflow: visible;
  padding: @spaceHalf;
  box-sizing: border-box;
  position: relative;

  .focus-catcher {
    height: inherit;
    width: inherit;
    border: solid @borderSize @borderColor;
    box-sizing: border-box;
  }
}

@keyframes blink-animation {
  to {
    opacity: .2;
  }
}

.GridRow {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
}

.GridColumn {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.LeftSideGridContainer {
  flex: 0 0 50px;
}

.isScrolling {
  opacity: .85;
}

.BodyGrid {
  width: 100%;
  background-color: @background;
}

.focusedRow, .selected {
  background-color: @secondary !important;
  color: white !important;
}

.cursor {
  background-color: darken(@secondary, 15%) !important;
}

.changed {
  opacity: .5;
}

.focusedCell {
  background-color: @secondary !important;
  color: @colorTextOpposite !important;
  font-weight: bold;
};

.evenRow,
.oddRow {
  border-bottom: 1px solid #e0e0e0;
}
.oddRow {
  background-color: #fafafa;
}

.HeaderGrid {
  width: 100%;
  overflow: hidden !important;  
  box-shadow: 0rem 0.25rem 0px rgba(0,0,0,.1); 
}

.footer-text {
  font-family: 'Accented';
  white-space: nowrap;
  color: fade(@primary, 80%);
}

.cell,
.headerСell,
.filterCell {

  .noselect;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: clip;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 .5em;
  box-sizing: border-box;
}

.cell {
  border-right: 1px solid @cellBorder;  
  border-bottom: 1px solid @cellBorder;
  contain: paint style layout;

  &.active {
    border-bottom:  @secondary solid @borderSize;
  }
}

.filterCell {
  padding: 0;
  border-right: @borderSize solid darken(@cellBorder, 10%);
  border-bottom: @borderSize solid darken(@cellBorder, 10%);
  border-top: @borderSize solid darken(@cellBorder, 10%);
  flex-direction: row;
  input[type=text] {
    height: unset;
  }

  .reset-icon {
    position: absolute;
  }
}

.filterField {
  background-color: @gridFilterCellBackground !important;
  color: @colorText;
  width: 100%;
  box-sizing: border-box;  
  border: none !important;
  text-align: left;
  padding: .25rem;
}

.tools-anchor {
  position: absolute;
  left: 0;
  bottom: 0;
  width: @space*2;
  height: @space*2;
}

.grid-tools  {  
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  border-top: solid @borderSize @borderColor;
  width: 100%;
  transition: opacity .25s ease-in-out;
  display: flex;
  align-items: center;
  background-color: @gridHeaderCellBackground;  
  user-select: none;
  backdrop-filter: blur(10px);
  padding: @spaceMini @spaceHalf;
  justify-content: space-between;

  .integrated {
    display: inline-flex;
  }

  * {
    user-select: none;
  }

  .tool {
    padding-inline-end: @space; 
    &:hover {      
      cursor: pointer;      
    }
    &.first {
      padding-inline-start: @spaceHalf;
    }

    &.pinned .icon {
      color: darken(@secondary, 20%);
    }
  }

  .icon {
    color: fade(@primary, 80%);
    font-size: .8em;
  }

  .total {
    padding: 0 @spaceMini;
    margin-inline-end: @space; 
    .footer-text()
  }
}

.header-box {
  background-color: @gridHeaderCellBackground;
}

.headerСell {
  color: @colorText;
  background-color: @gridHeaderCellBackground;
  border-right: @gridHeaderCellBackground;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  font-family: Accented, sans-serif;
  padding: 0;
  overflow: visible;

  .controls {
    padding: @spaceMini;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%; 
    overflow: clip;
  }

  &.active {
    background-color: @primary;
    color: if(@DARK_MODE, @colorText, @colorTextOpposite);
    &:hover {
      background-color: darken(@primary, 20%)
    }

    .icon {
      color: @colorTextOpposite
    }
  }

  .adjust {
    background: transparent;
    height: 100%;
    width: 5px;
    cursor: col-resize;
    z-index: 900;
    margin-inline-end: -3px;

    transition: transform .1s ease;
    &.drag-handle-active {
      background-color: @secondary;
    }

    &:hover {
      background-color: darken(@secondary, 20%)
    }
  }

  .header-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;    
  }

  .icon {
    color: @colorText;
  }

  &:hover {
    cursor: pointer;
    background-color: lighten(@gridHeaderCellBackground, 10%);
  }
}

.centeredCell {
  align-items: center;
  text-align: center;
}

.letterCell {
  font-size: 1.5em;
  color: #fff;
  text-align: center;
}

.noCells {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: #bdbdbd;
}

.grid-overlay {
  outline: solid @borderSize @borderColor;
  height: inherit;
  width: inherit;
  overflow-y: hidden;
}

.grid-overlay.placeholder::after {
  content: attr(placeholder);
  color: @colorTextMuted;
  position: relative;
  left: .5rem;
  top: 3.5rem;  
}

.grid-overlay.placeholder.blink::after {
  color: @colorText;
  animation: blink-animation .5s steps(18, start) infinite;
}

.cell.count-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0;
}

.call-status-icon {
  max-width: @spaceHalf + @spaceMini;
  min-width: @spaceHalf + @spaceMini;
  display: flex;
  align-items: center;
  justify-content: center;
}

.droppable-cell {
  background: repeating-linear-gradient(
    45deg,
    fade(@background, 5%),
    fade(@background, 5%) 5px,
    fade(@primary, 5%) 5px,
    fade(@primary, 5%) 7px
  );
}

.drop-allowed {
  filter: invert(1)
}

.paginator {
  display: inline-flex;
}

.nav-tool {
  .footer-text();
  padding: 0 @spaceHalf;
  cursor: pointer;
}

.page-counter {
  margin-inline-end: @spaceMicro;
}