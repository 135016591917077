// QC: PASSED


.auto-expandable {
  height: auto; 
}

.modal-layer {
  position: absolute;
  
  @media @smartphones {
    position: static;
  }

  left: 0;
  top: 0;
  z-index: 1;
  
  .modal {    
    position: absolute;    
    contain: style layout;
    border: solid 1px @modalOutline !important;
    box-sizing: border-box;

    box-shadow: 10px 10px 1px @boxShadowColor;
    background: lighten(@background, 10%);
    transition: outline .15s ease-out;
    z-index: 20;
    will-change: transform, opacity, filter;
    filter: saturate(20%) contrast(0.9);

    &.isActive {
      border: solid 1px @activeModalOutline !important;
      z-index: 20000 !important;
      filter: saturate(100%);

      &.react-draggable-dragging {
        opacity: .85;
      }
    }

    @media @smartphones {
      width: 100% !important;
      height: 100% !important;
      margin-inline-start: 0 !important;
      font-size: 1.1em;
      transform: none !important;
      left: 0 !important;
      top: 0 !important;
      z-index: 1 !important;
      border: none;
      box-shadow: none;
      &.active {
        outline: none !important;
      };
      .buttons {
        border: none !important;
      }
    }

    &.isMinimized {
      transform: translateY(calc(100vh + @space)) !important;
    }

    &.isMaximized {
      &:not(.isMinimized) {
        transform: none !important;
        width: 100vw !important;
        height: calc(100vh - 2.7rem) !important;
        left: 0 !important;
        top: 0 !important;
        
        .h800px, .h600px, .h400px, .h300px, .h200px, .h100px, .h40vh, .h30vh {
          height: 100%;
        }

        .auto-expandable {
          height: 100%; 
        }
      }      
    }

    .help-content {      
      width: 15rem;
      overflow: hidden;
      text-overflow: ellipsis;
      position: absolute;
      transform: translateX(-15rem);
      background: @primary;
      background-image: linear-gradient(45deg,  darken(@primary, 25), transparent);
      outline: solid 1px @primary;
      height: 100%;
      color: @colorTextOpposite;      
      box-sizing: border-box;

      .title {
        .title-text {
          color: @colorTextOpposite;
          .icon {
            color: @secondary !important;
          }
        }
      }
      
      .content-body {
        white-space: pre-wrap;
        overflow: auto;
      }

      .footer {
        padding: @spaceHalf;
        border-top: solid 1px darken(@primary, 2%) !important;
        justify-content: center;
        .field-button {
          border: none;
          background: transparent
        }
      }
    }

    .modal-content, .help-content {
      .noselect;
      display: flex;
      flex-direction: column;
      height: 100%;
      box-sizing: border-box;

      @media @smartphones {        
        justify-content: space-between;
      }

      .title {
        cursor: pointer;
        font-size: 1.6em;
        text-align: left;
        @media @smartphones {          
          outline: none;
          background:  @primary;
          color: @colorTextOpposite;
          padding: @spaceMini;
          font-size: 1.4em;
          display: none;
        }
        &:active {
          cursor: move;
        }

        font-family: Accented, sans-serif;
        display: flex;
        justify-content: space-between;
        
        .title-text {
          padding: @spaceMicro @spaceHalf+@spaceMini;
          flex-grow: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &.invisible {
            width: unset !important;
            height: unset !important;
            position: unset !important;
          }

          .ellipsize {
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .icon {
            color: fade(@secondary, 70%);
            margin-inline-end: @spaceHalf;
            font-size: .8em;

            @media @smartphones {
              color: @colorTextOpposite;
            }
          }
        }
        .action {
          font-size: .5em;
          padding: 0 @space;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: background-color .05s ease-in;
          
          &.disabled {
            opacity: 0.1;
          }
          &:hover {
            .icon {            
              color: @colorTextOpposite;
            }
          }
        }
        .collapse, .expand, .help  {
          @media @smartphones {
            display: none;
          }
          &:hover {            
            background: @backgroundButton;
              .icon {
                color: @colorTextOpposite;
              }          
          }
        }
        .cross {
          .icon {            
            color: red;
          }

          @media @smartphones {
            .icon {
              color: lighten(@backgroundError, 50%);
            }
          }
          &:hover {
            background: @backgroundError;
            .icon {            
              color: @colorTextOpposite;
            }
          }
        }
      }
      .body {
        overflow: visible;
        height: 100%;
        margin-block-start: 5px;
        padding: @spaceMini;

        @media @smartphones {        
          overflow: auto;
          display: flex;
          align-items: center;
          
          >* {
            width: 100%;
            max-width: 100%;
          }          
        }  
      }
      .error {
        background-color: @backgroundError;
        text-align: center;
        width: 100%;
        font-weight: bold;
        transition: opacity .5s ease-in-out;        
        opacity: 0;
        height: 0;
        position:absolute;

        &.active {
          position: static;
          opacity: 1;
          height: auto;
          
          .error-text {
            display: block;
            color: @colorTextOpposite;
            padding: @space;
          }
        }        

        &.blink {
          background-color: @backgroundWarning;
        }
      }
      .footer {
        display: flex;
        border-top: solid @borderSize lighten(@borderColor, 5%);
        .extra {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          box-sizing: border-box;          
        }

        .buttons {
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          box-sizing: border-box;
          padding: @spaceMini;
          overflow: hidden;
          
          .button {
            margin-inline-start: @spaceMini;
            overflow: hidden;
            text-overflow: ellipsis;

            @media @smartphones {
              background-color: lighten(@primary, 25%);
            }

            span {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        @media @smartphones {
          background: @primary;
        }
      }
    }
  }
}

.rtl {
  .modal-layer .modal .help-content {   
    transform: translateX(15rem)
  }
}