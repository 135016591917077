/*
 * @file about.less
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Monday, 8th July 2019 6:23:06 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 */
@keyframes smoothAppear {
  from {
    filter: blur(10rem);
  }
  to {
    filter: blur(0);
  }
}

 .modal.about-window, .modal.about-window.isActive {
  //background-color: @primary !important;
  color: @colorTextOpposite;
  border-color: @primary;
  outline: none !important;
  border: none !important;
  overflow: hidden;
  box-sizing: border-box;

  background-color: fade(@primary, 50%);
  background-image: linear-gradient(45deg,  darken(@primary, 25), transparent);
  backdrop-filter: blur(@space);

  .icon, .title-text, a, .action {
    color: @colorTextOpposite !important; 
  }

  .modal-content {
    .footer {
      border: none;
    }
  }
}

 .stats-title {
    white-space: nowrap;
    animation: animated-text 1s steps(30, end) 0s 1 normal both;   
 }


.about-view {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;

  h1, h2, h3, h4 {
    color: @colorTextOpposite;
    font-family: 'Normal';
  }

  h4 {
    margin-top: 1em;
  }  

  .a-decoration {
    position: absolute;
    font-size: 30rem;
    line-height: 100%;
    color: rgba(255,255,255,0.1);
    font-weight: bold;
    right: -10rem;
    z-index: -1;
    pointer-events: none;
    animation: smoothAppear ease-in-out .5s;
  }  

  .tab-view {
    margin-top: @space*2;
    .dynamic-tab-body {
      border: solid 1px rgba(255, 255, 255, 0.2);
     .tab {
        background: rgba(0, 0, 0, 0.4);
        color: @colorTextOpposite;

        .translations {
          overflow: auto;
          height: 100%;
          box-sizing: border-box;
        }
      }
    }
  }
}

.web-hooks {
  box-sizing: border-box;
  height: 100%;
  overflow: auto;

  .hook {
    display: flex;
    flex-direction: column-reverse;
    box-sizing: border-box;
    padding: @spaceMini;
    background: rgba(0,0,0,.05);
    margin-bottom: 1px;

    .name {      
      .muted();
      font-size: .8em;
      font-weight: bold;
    }    
  }
}


.changelog {
  overflow: auto;
  padding: @spaceHalf;
  height: 100%;
  box-sizing: border-box;

  h1 {
    color: @secondary;
  }

  ul {
    margin-left: @space * 2;
  }

  p {
    margin-top: @spaceHalf;
    margin-bottom: @space;
  }

  code {
    white-space: break-spaces;
    color: @colorTextOpposite;
    border: none;
    font-family: 'Normal';
  }
}

.rtl .a-decoration {
  right: unset;
  left: -10rem;
}