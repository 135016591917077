/*
 * @file datePcikerOverrides.less
 * @project Web-panel
 * @author Pavel Shabardin (<bigbn@mail.ru>) Monday, 15th July 2019 12:54:46 pm
 * @copyright 2015 - 2019 SKAT LLC, Delive LLC
 */
 @import '../../../../styles/_variables.less';

.react-datepicker {
  font-family: 'Normal'
}

.react-datepicker__header {
  background-color: @primary;
  color: @colorTextOpposite;
  border: none;
  border-radius: 0;
}

.react-datepicker__day-name, 
.react-datepicker__current-month,
.react-datepicker-time__header {
  color: @colorTextOpposite;
}

.react-datepicker__navigation {
  box-shadow: none;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker,
.react-datepicker__time-container--with-today-button {
  border-radius: 0;
}

.react-datepicker__today-button {
  background-color: lighten(@primary, 50%)
}

.react-datepicker__day--keyboard-selected, 
.react-datepicker__month-text--keyboard-selected {
  background-color: @secondary;
  border-radius: 0;
  &:hover {
    background-color: lighten(@secondary, 10%);
    border-radius: 0;
  }
}

.react-datepicker__day {  
  &:hover {
    border-radius: 0;
  }
}

.react-datepicker__time-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}


.react-datepicker-wrapper, .react-datepicker__input-container {
  display: block;
}