@import '_variables.less';

/**
* Переиспользуемые класыы
*/
.clear {
  clear: both;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden;
  overflow: hidden;
  width: 0 !important;
  height: 0 !important;
  position: absolute;
}

.no-overflow {
  overflow: hidden !important;
}

.auto-overflow {
  overflow: auto !important;
}

.conceal {
  transform: translateY(100vh) !important;
  transition: none !important;
  contain: content;
}

.visible {
  visibility: visible;
}

.c-a, .a-c, .center-aligned {
  text-align: center;
}

.c-a-f {
  justify-content: center !important;
  align-items: center;
  display: flex;
}

.r-a, .a-r, .right-aligned {
  text-align: right;
}

.flex-right-aligned {
  justify-content: flex-end;
}

.left-spaced {
  margin-inline-start: .5em;
}

.right-spaced {
  margin-inline-end: .5em;
}

h1, h2, h3, h4, h5, .title, i.fa-solid {
  color: @colorTitle;
}

.expander {
  flex-grow: 2;
}

.accented {
  background: @backgroundError;
  color: @colorTextOpposite;
}

.accented-font {
  font-family: Accented;
}

.dashed, .call-link {
  text-decoration-line: underline;
  text-decoration-style: dashed;
  cursor: pointer;
}


.ltr {
  direction: ltr !important;
}

.pre-line {
  white-space: pre-line;
}

.small {
  font-size: 0.7rem;
}

.un-fade {
  opacity: 1;
  .transition(opacity .15s ease-in-out);
}

.fade {
  opacity: 0;
  .transition(opacity .15s ease-in-out);
}

.smooth {
  .transition(opacity .15s ease-in-out);
}

.faded {
  opacity: 0;
}

.pending {
  background: linear-gradient(-45deg, @primary 25%,@primary 25%,@secondary 25%,@secondary 50%,@primary  50%, @primary  75%,@secondary 75%, @secondary 100%);
  background-size: 200% 200%;
  animation: endlessMove .3s linear infinite;
  opacity: .5;
}


.width-100, .v100, .full-width {
  width: 100%;
}

.width-99 {
  width: 99%;
}

.spinner {
  cursor: wait !important;
}

.green {
  color: green !important;
}

.red {
  color: red !important;
}

.processing {
  cursor: wait;
}


.no-margin {
  margin: 0
}
.no-left-margin {
  margin-inline-start: 0
}
 
.no-top-margin {
  margin-block-start: 0
}

.no-right-margin {
  margin-inline-end: 0
}

.no-botton-margin {
  margin-block-end: 0
}

.no-border {
  border: none;
}

.vertical-flex {
  display: flex;
  flex-direction: column;
}

.h100, .full-height {
  height: 100%;
}

.h100px {
  height: 100px;  
  @media @smartphones {
    height: 100%;
  }
}

.h200px {
  height: 200px;  
  @media @smartphones {
    height: 100%;
  }
}

.h300px {
  height: 300px;  
  @media @smartphones {
    height: 100%;
  }
}

.h400px {
  height: 400px;  
  @media @smartphones {
    height: 100%;
  }  
}

.h40vh {
  height: 40vh;  
  @media @smartphones {
    height: 100%;
  }  
}

.h30vh {
  height: 30vh;  
  @media @smartphones {
    height: 100%;
  }  
}

.h600px {
  height: 600px;
  @media @smartphones {
    height: 100%;
  }
}

.h800px {
  height: 800px;
  @media @smartphones {
    height: 100%;
  }
}


.a-v, .v-a {
  display: flex;
  align-items: center;
}

.a-h, .h-a {
  display: flex;
  justify-content: center;
}

.h-a-important {
  display: flex;
  justify-content: center !important;
}

.half-padding {
  padding: @spaceHalf;
}

.half-top-padding {
  padding-top: @spaceHalf;
}

.half-bottom-padding {
  padding-bottom: @spaceHalf;
}

.half-right-padding {
  padding-right: @spaceHalf;
}

.half-left-padding {
  padding-left: @spaceHalf;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.full-padding {
  padding: @space;
}

.mini-padding {
  padding: @spaceMini;
}

.mini-top-padding {
  padding-top: @spaceMini;
}

.mini-bottom-padding {
  padding-bottom: @spaceMini;
}

.mini-left-padding {
  padding-left: @spaceMini;
}


.mini-right-padding {
  padding-right: @spaceMini;
}

.no-top-padding {
  padding-top: 0;
}

.full-margin {
  margin: @space;
}

.half-margin {
  margin: @spaceHalf;
}

.mini-margin {
  margin: @spaceMini;
}

.full-bottom-margin {
  margin-block-end: @space;
}

.full-top-margin {
  margin-block-start: @space;
}

.half-top-margin {
  margin-block-start: @spaceHalf;
}

.half-bottom-margin {
  margin-block-end: @spaceHalf;
}

.half-left-margin {
  margin-inline-start: @spaceHalf;
}

.half-right-margin {
  margin-inline-end: @spaceHalf;
}

.full-right-margin {
  margin-inline-end: @space;
}

.full-left-margin {
  margin-inline-start: @space;
}

.mini-left-margin {
  margin-inline-start: @spaceMini;
}

.micro-margin {
  margin: @spaceMicro;
}
.micro-top-margin {
  margin-block-start: @spaceMicro;
}
.micro-left-margin {
  margin-inline-start: @spaceMicro;
}
.micro-right-margin {
  margin-inline-end: @spaceMicro;
}
.micro-bottom-margin {
  margin-block-end: @spaceMicro;
}

.border-boxed {
  box-sizing: border-box
}


.non-flex {
  flex: none;
}

.bold {
  font-weight: bold
}

.uppercase {
  text-transform: uppercase;
}

.muted {
  color: @colorTextMuted;
  .checkbox {
    outline-color: @colorTextMuted;
  }
}

.minimal-text-size {
  font-size: 9px;
}

.w10rem {
  width: 10rem;
}


.pre-wrap {
  white-space: pre-wrap;
}

a.inline-action {
  text-decoration-skip-ink: none;
  text-decoration: underline dashed;
  cursor: pointer;
  display: inline-flex
}

.interactive {
  cursor: pointer;
  pointer-events: visible;
  opacity: .7;
  transition: opacity 150ms ease-in-out;
  &:hover {
    opacity: 1;
  }
}

.backward-outline {
  outline: solid 1px @borderColor;
  outline-offset: -1*@spaceHalf;
}

.hidden-visibility {
  visibility: hidden;
}

.border-none {
  border: none !important;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}


.flex-max {
  flex-grow: 9999;
}

.flex-min {
  flex-grow: 1;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.code {
  font-family: monospace;
  color: darkred;
}

.pre-line {
  white-space: pre-line;
}

.space-between {
  justify-content: space-between;
}

.rounded-borders {
  border-radius: @spaceMini;
}

.relative {
  position: relative;
}

.opposite {
  color: @colorTextOpposite
}
