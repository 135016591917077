// QC: HOLD
@isRtl: boolean(false);
@remUnit: 16px;

@primary: if(iscolor(@palette_secondary), @palette_secondary,  #325f79);
@secondary: if(iscolor(@palette_primary), @palette_primary,  #f8a638);
@supplimentary: if(iscolor(@palette_supplimentary), @palette_supplimentary,#76b721);
@background: if(iscolor(@palette_background), @palette_background,#FFFFFF);
@foreground: if(iscolor(@palette_foreground), @palette_foreground,#000000);

@DARK_MODE: boolean(luma(@background) < 50%);

@colorText: @foreground;
@colorTextMuted: fade(lighten(@colorText, 30%), 50%);
@colorTitle: if(@DARK_MODE, darken(@foreground, 5), @primary);
@colorLink: @primary;
@colorIcon: @primary;
@colorTextOpposite: @background;
@colorShadow: fade(@primary, 50%);

@toolbarBackground: @primary;

@DARK_TOOLBAR:  boolean(luma(@toolbarBackground) < 50%);

@toolbarColor: if(@DARK_TOOLBAR,  
  if(@DARK_MODE, @colorText, @colorTextOpposite), 
  if(@DARK_MODE, @colorTextOpposite, @colorText)
);

@toolbarColorHover: @colorTextOpposite;

@space: 0.8rem;
@spaceHalf: .4rem;
@spaceMini: .2rem;
@spaceMicro: .1rem;

@borderSize: 1px;
@borderColor: if(@DARK_MODE, darken(@foreground, 70%), screen(@primary, #BBB));
@focusBorderColor: @secondary;
@boxShadowColor: rgba(0, 0, 0, 0.1);

@backgroundHeader: @primary;
@backgroundSidebar: if(@DARK_MODE, darken(@background, 5%), lighten(@primary, 15%)); 
@backgroundFooter: darken(@primary, 15%);
@backgroundInput: if(@DARK_MODE, darken(@background, 10%), darken(@background, 1%));
@backgroundPanel: #F5F5F5;
@backgroundError: red;
@backgroundWarning: rgb(255, 94, 0);

@backgroundButton: @primary;
@backgroundButtonHovered: darken(@primary, 7%);
@colorTextButton: if(@DARK_MODE, @foreground, @colorTextOpposite);

@backgroundPrimaryButton: @secondary;
@backgroundPrimaryButtonHovered:  darken(@secondary, 10%);
@backgroundPanelButton: @background;
@backgroundPanelButtonHovered: lighten(@primary, 60%);


@inputHeight: 1.6rem;
@mobileInputHeight: 2.2rem;

@modalOutline: @borderColor;
@activeModalOutline: @borderColor;

@smartphones: ~"only screen and (max-width: 479px)";
@tablets: ~"only screen and (min-width: 768px) and (max-width: 959px)";

.activeElement {
  transition: background-color .15s ease;
  cursor: pointer;
}

@gridHeaderCellBackground: if(@DARK_MODE, lighten(@background, 10%), screen(@primary, #DDD));
@gridFilterCellBackground: if(@DARK_MODE, lighten(@background, 5%), screen(@primary, #CCC));
@cellBorder: if(@DARK_MODE, @borderColor, @borderColor);

@canvasFilter: if(@DARK_MODE, invert(1), none);

@scrollbarColor: if(@DARK_MODE, lighten(@background, 15%), screen(@primary, #777));
@scrollbarBackground: if(@DARK_MODE, lighten(@background, 10%), screen(@primary, #DDD));


@unit: 1rem;
@fontFamily: 'Normal', sans-serif;

@headerHeight: 3.5rem;
@sidebarWidth: 10rem;
