/** REACT COMPONENTS */
 
.field {
  position: relative;  
  height: @inputHeight;
  align-items: center;
  overflow: hidden;
  outline-offset: -1px;

  s { // this is dropdown mount anchor
    position: absolute;
    left: -1px;
    top: @space*2;
  }
 
  display: flex;
  box-sizing: border-box;  
  background: @backgroundInput;
  border: solid @borderColor @borderSize;
  transition: all .25s ease-in-out;

  &.focused {    
    outline: solid @focusBorderColor @borderSize;      
  }
}

.rtl {
  .field {
    s {
      left: unset;
      right: -1px;
    }
  }
}

&.disabled {
  opacity: .3;
}

.field-checkbox {
  overflow: hidden;
  display: inline-flex;
  &.disabled {
    opacity: .3;
  }
}

.checkbox-label {
  overflow: hidden;
  text-overflow: ellipsis;
}


@innerButtonSize: calc(~'1.6rem - 6px');

.field-button {
  border-radius: 1px;
  cursor: pointer;
  
  border: solid @borderSize if(@DARK_MODE, darken(@backgroundButton, 10%), lighten(@backgroundButton, 10%));
  background-color: if(@DARK_MODE, @backgroundButton, lighten(@backgroundButton, 20%));
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .25s ease-in-out;
  box-sizing: border-box;
  
  height: @innerButtonSize;  
  max-width: @innerButtonSize;
  min-width: @innerButtonSize;
  

  width: @innerButtonSize;
  
  margin-inline-end: 0.125rem;
  
  &:first-child {
    margin-inline-start: 0.125rem;
  }

  &.non-interactive {
    border: none;
    background-color: transparent;
    color: if(@DARK_MODE, darken(@backgroundButton, 20%), lighten(@backgroundButton, 20%));
    cursor: help;
    
    &:hover {
      background-color: transparent;
    }
  
    &.active {
      background-color: transparent;
      border-color: transparent;
    }  
  }

  &:hover {
    background-color: @backgroundButtonHovered;    
  }

  &.active {
    background-color: @secondary;
    border-color: darken(@secondary, 15%);
  }

  .icon {
    color: if(@DARK_MODE, @colorText, @colorTextOpposite);
  }
 
  &.disabled {
    opacity: .3
  }


}

.address-box, .address-controls {
  display: flex;
  flex-direction: row;
}

.address-box {
  max-width: 100%;
  @media @smartphones {
    flex-wrap: wrap;
  }
  .tip {
    display: flex;
    align-items: center;
    padding: 0 @spaceHalf;
    background-color: @secondary;
    color: @colorTextOpposite;
    max-width: 6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media @smartphones {
      max-width: none;
      padding: @spaceHalf;
    }
  }
}

@media @smartphones {
  .l4 { 
    flex: auto; 
    margin-block-start: @spaceHalf; 
    width: 100%;
    justify-content: flex-end;
    border: none;
  }  
}

.field-address {
  max-width: 100%;
  width: 100%;
  min-width: 0;
  margin-inline-end: @spaceHalf; 

  &.l1 { flex: 1.5 }
  &.l2 { flex: 0.55 }
  &.l3 { flex: 0.45 }

  @media @smartphones {
    margin-inline-end: 0;
    &.l1 { flex: auto; margin-block-start: @spaceHalf; /* max-width: 60%; */}
    &.l2 { flex: auto; margin-block-start: @spaceHalf; /* max-width: 40%; */}
    &.l3 { flex: auto; margin-block-start: @spaceHalf; /* max-width: 60%; */}  
  }

  .city {
    min-width: 0;
    height: calc(1.6rem - @borderSize * 2);    
    width: 14rem;
    transition: width .125s ease-in;
    &.collapsed {
      width: 0;
      border: 0; 
      visibility: hidden;
    }
  }

  .street {
    flex-grow: 1;
    min-width: 10rem;
  }

  .district {
    min-width: 0;
  }

  .entrance, .apartment, .floor {
    flex: 1;
    min-width: 0;
  }

  .house {
    max-width: 2.5rem !important;
    border-right: none !important;
  }

  .field, input {    
    border: none;
    border-right: solid @borderColor @borderSize;
    &:focus, &.focused {
      outline: none
    }
    &:last-child {
      border-right: none;
    }
  }
}
 
.field-autocomplete, .field-semihidden {
  justify-content: space-around;
  
  &.ref-select {

    background: if(@DARK_MODE, darken(@backgroundButton, 40%), screen(@backgroundButton, #DDD));
    cursor: pointer;
    
    @media @smartphones {
      // height: 100%;
      width: 100%;
    }

    .field-data .field-input:disabled {
      background: transparent;
      box-shadow: none;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .field-button {
      border-color: transparent;
    }
    .field-dropdown {
      color: @colorText;
    }

    .field-input.resolved {
      text-decoration: none;
    }

    .field-input[readonly] {
      opacity: 1;
      cursor: pointer;      
      text-overflow: ellipsis;
      user-select: none;
    }    
  }

  &.pending {
    .pending();
  }

  &.inverse-colors {
    background: darken(@primary, 5%);
    border-color: lighten(@primary, 10%);

    &.focused {
      box-shadow: 0px 0px 2rem darken(@secondary, 40%) inset;
    }

    .field-data .field-input:disabled, .field-input[readonly] {
      background: transparent;
      color: @colorTextOpposite;    
    }

    .field-button {
      border-color: transparent;      
    }

    .field-dropdown {
      color: @colorText;
    }    
  }
  
  .field-data {
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
    overflow: hidden;    
    flex-grow: 1;
    box-sizing: border-box;
    height: 100%;
    flex-direction: row;

    .field-value {      
      background: lighten(@primary, 60%);
      padding: @spaceMicro;
      display: flex;      
      align-items: center;
      margin-inline-end: @spaceMini;
      border: solid @borderColor @borderSize;
      border-radius: 2px;

      .text {
        display: flex
      } 

      .icon {
        padding: @spaceMicro;
        color: @backgroundError;
        cursor: pointer;
      }
    }

    .field-input {
      flex-basis: @space;
      padding: 0 @spaceMini;
      flex-grow: 1;
      min-width: @inputHeight;
      -webkit-appearance: none;
      appearance: none;
      border: none;
      height: 100%;      
      outline: none;
      text-indent: 0;
      background: transparent;
      font-size: 1em;
      display: flex;     
      &:disabled {
        background: @backgroundInput;
      } 
      &.resolved {
        text-decoration: underline;
      }
    }
  }
  
  .field-reset {
    display: none !important;
    padding: @spaceMicro;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1rem;
    .icon {
      color: @borderColor;
    }
  }
}

.field-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  box-shadow: @spaceMini @spaceMini 0 @colorShadow;
  background: @background;
  max-height: 25rem;
  min-width: 15rem;
  overflow: auto;
  border: solid @borderColor @borderSize;
  z-index: 100;
  transform: translate3d(0, 0, 0);
  will-change: transform;

  &.in-bottom, &.in-right-bottom {
    transform: translateY(-100%);
    margin-block-start: -1 * @space*2; 
  }
  
  .item {
    padding: @spaceMini;
    min-height: @inputHeight - @spaceHalf;
    border-bottom: solid lighten(@borderColor, 10%) @borderSize;      
    cursor: pointer;
    display: flex;
    align-items: center;

    &.focused {
      background-color: @secondary;
    }
    &.active {
      background-color: black;
    }
    &:hover {
      background: @primary;
      color: @colorTextOpposite;
    }
  }
}

.rtl {
  .field-dropdown {
    left: unset;
    right: 0
  }
}


textarea {
  min-height: 3.4rem;
  width: 100%;
}

.w100 {
  width: 100%;
}

.shortcut-editor {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: @space;

  input {
    margin: 1rem;
    padding: @space;
    font-size: 2em;
    height: auto;
  }
}

.left-label {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
}

.resolving {
  animation: blink-animation .5s steps(18, start) infinite;
}

.invalid {
  background: #FEE !important;
  border-color: #FAA !important;
  * {
    color: darkred;
  }

  .field-button {
    background: red;
    border-color: red;
    * {
      color: @colorTextOpposite;
    }
  }
}

.links-line {
  a {
    margin-inline-end: @spaceHalf;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-style: dashed;
    color: @primary;
  }
}

.tree-icon {
  min-width: 1rem; 
}

.tree-handler {  
  padding: @spaceMicro @spaceMini;
  padding-inline-end: 0;
}

.tree-node-text {
  padding: @spaceMicro @spaceMini;
  white-space: nowrap;
}

.tree-node {  
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;  
}

.tree-overlay {
  outline: solid @borderSize @borderColor;
  height: inherit;
  overflow-y: hidden;
  width: 100%;

  .tree-search {
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: solid 1px @borderColor;
    width: 100%;
  }
}

.no-borders {
  border: none !important
}



.brief-box {
  color: if(@DARK_MODE, @colorText, @colorTextOpposite);
  background: @backgroundButton;
  width: 100%;
  box-sizing: border-box;
  max-height: 350px;
  overflow-x: hidden;
  overflow-y: auto;  
  user-select: text;

  .brief-title {    
    color: lighten(@secondary, 5%);
    background: darken(@backgroundButton, 10%);
    cursor: pointer;
    &:hover{
      background: darken(@backgroundButton, 20%);
    }
    .arrow-icon {      
      .icon  {
        color: @secondary;
      }
    }
  }

  .warn {
    padding: @spaceMini  @spaceHalf;
  }

  .warn {
    background: darken(@secondary, 15%);
    font-weight: bold;
    text-align: center;
    font-family: 'Accented';
  }

  .err {
    background: red;
    font-weight: bold;
    text-align: center;
    font-family: 'Accented';
  }

  .info {
    display: table;
    width: 100%;
    box-sizing: border-box;

    div {
      display: table-row;
      span {
        display: table-cell;
        padding: @spaceMini/2 @spaceMini;
        &.key {
          width: 1em;
          white-space: nowrap;
          font-family: Accented;
          padding-inline-end: @spaceHalf;
          text-align: right;
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .value {
    white-space: pre-wrap;
  }
}

.reset-icon {
  cursor: pointer;
  padding-inline-end: @spaceMini;
  height: 100%;
  right: 0;
  transition: opacity 150ms ease-in-out;
  opacity: .1;

  display: flex;
  align-items: center;

  &.active { 
    opacity: .8;
    &:hover {
      opacity: 1;
    }
  }
  &:active {
    color: @secondary;
  }
}

.inline-calculator {
  position: absolute;
  z-index: 10;
  background: @background;
  border: solid 1px @borderColor;
  padding: @spaceMicro @spaceMini;
  min-width: 10rem;
  max-width: 10rem;
  overflow: hidden;
    
  .fake-display {
    min-height: @space+@spaceHalf;
    background: @primary;
    color: white;
    padding: @spaceMini @spaceHalf;
    width: 100%;
    font-family: monospace;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 3px;
  }
}


.managed-tooltip {
  position: absolute;  
  background: @backgroundFooter;  
  opacity: .95;
  z-index: 100000;
  color: @colorTextOpposite;
  padding: @space;
  border-radius: 4px;
  max-width: 20rem;  
  margin-inline-start: @space;
  margin-block-start: @space;
  transition: visibility 0s linear 300ms, opacity 300ms;
  pointer-events: none;

  &.in-right {
    transform: translateX(-100%);
    margin-inline-start: -1 * @space;    
  }

  &.in-bottom {
    transform: translateY(-100%);
    margin-block-start: -1 * @space; 
  }
  
  &.in-right-bottom {
    transform: translateX(-100%) translateY(-100%);
    margin-block-start: -1 * @space;
    margin-inline-start: -1 * @space;
  }

  &.concealed {
    visibility: hidden;
    opacity: 0;    
  }

  .icon-wrapper {
    float: left;
    padding-inline-end: @spaceHalf;
    height: 1rem;
    align-items: center;
  }

  .tip-icon {
    color: @secondary;
  }
}

.required-mark {
  color: red;
  font-weight: bold;
  margin-inline-end: @spaceMini;
}

.inline-action {
  .icon-wrapper {
    display: inline;
    margin-inline-end: @spaceHalf;
  }
}

.spinner-component {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  width: inherit;

  span {
    margin-inline-start: @spaceMini;
  }
}


.form-group-title {
  margin: 0 @spaceHalf+@spaceMini;
  text-transform: uppercase;
  font-family: 'Accented';
  opacity: .4;
  color: @colorText;
  border-bottom: dotted 1px @borderColor;
  margin-block-start: @space;
}

.form-group {  
  .half-padding();
  .half-margin();
  .border-boxed();
  .rounded-borders();  
}

.virtual-list {
  &.has-above::before {
    content: '';
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    height: @space * 2;    
    background: linear-gradient(@backgroundFooter, #0000);
    pointer-events: none;
  }
  
  &.has-below:after {
    content: '';
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: @space * 2;    
    background: linear-gradient(#0000, @backgroundFooter);
    pointer-events: none;
  }

  &.has-both {
    box-shadow: 0 -1rem 1rem black inset,  0px 1rem 1rem black inset;
  }
}