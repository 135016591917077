// QC: PASSED
#layout-region {
  width: inherit;
  height: inherit;
  display: flex;
  flex-direction: column;
}

#content-region {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;

  .main {
    flex: 1;
    width: inherit;
    height: inherit;
    will-change: width;
    
	  .extra-padding {
      height: inherit;

      #main-region {
       background: url(@icons_logo_default_main) 90% 90% no-repeat;       
       background-size: 300px;
       
       @media @smartphones {
        background: url(@icons_logo_default_main) center no-repeat;
       }
      }
      
      .pages {
        background: fade(@background, 80%);
      }

      #main-region, .pages, .page-body {        
        position: relative;
        height: inherit;
        width: 100%;
       
        .container {
          height: inherit;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        
        .page-body {          
          contain: strict;
          height: inherit;
          position: absolute;
          width: 100%;       
          overflow: hidden;   
        }
      }

      .layout {
        width: 100%;
        .changable {
          outline: dashed 2px black;
          outline-offset: 3px;
        }
      }

      .no-acess-message {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: @background;
        text-align: center;
        font-family: monospace;
        p {
          color: @colorTextMuted;
        }
      }
	  }
  }
}

