// QC: PASSED
@footerHeight: 2.7rem;

#footer-region {
  height: @footerHeight;
  max-height: @footerHeight;
  min-height: @footerHeight;
  background: @backgroundFooter;
  // transition: transform 1s ease-in-out;
  // will-change: transform;
 
  .modals-controls {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .modals-collapse {
      width: 1rem;
      min-width: 1rem;
      background: darken(@backgroundFooter, 20%);
      cursor: pointer;
      &:hover {
        background: lighten(@backgroundFooter, 10%);
      }
    }
  }
  .modal-list {
    .noselect;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    max-height: @footerHeight;
    scroll-behavior: smooth;

    .modal-indicator {
      .noselect;
      background: lighten(@backgroundFooter, 10%);
      filter: saturate(0.2) brightness(.8);
      transition: background-color .1s ease;
      color: if(@DARK_MODE, @colorText, @colorTextOpposite);
      display: flex;
      align-items: center;
      justify-content: space-between;      
      box-sizing: border-box;
      min-width: 10rem;
      max-width: 10rem;
      overflow: hidden;

      margin: @spaceMini;
      flex-direction: row-reverse;
      .icon-box {
        .icon {
          padding-inline-start: @space;
          padding-inline-end: @spaceMini;
          color: if(@DARK_MODE, @secondary, @secondary);
        }
      } 
      .info-box {
        justify-content: flex-start;
        box-sizing: border-box;
        flex-direction: column;
        align-items: flex-start;
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }   

      margin-inline-end: 0;
      margin-inline-start: @spaceMini;
      
      cursor: pointer;
      &:hover {
        background: lighten(@backgroundFooter, 20%);
      }

      &.isCollapsed {
        background: darken(@backgroundFooter, 10%);    
        label, span {
          color: darken(@colorTextOpposite, 30%);
        }    
        &:hover {
          background: darken(@backgroundFooter, 5%);
        }
      }
      
      &.isFlashed {
        animation: flashes 1s linear infinite;
      }

      label {
        padding: 0 @spaceMini;        
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        font-weight: bold;
      }

      span {
        padding: .1rem @spaceMini;
        font-size: .8em;        
        color: if(@DARK_MODE, darken(@colorText, 15%), darken(@colorTextOpposite, 15%));
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }

      &.isActive {
        filter: saturate(1.2) brightness(1.2);
      }
    }
  }
}


.context-block {
  height: @footerHeight;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 .5rem;
  justify-content: center;
  white-space: nowrap;

  span {
    font-size: .8em;
    color: fade(if(@DARK_MODE, @colorText, @colorTextOpposite), 75%);
  }
}


.extra-context-button {
  padding: 0 @space;
  width: @space + @spaceHalf + @spaceMini;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: if(@DARK_MODE, @colorText, @colorTextOpposite);
  opacity: .8;

  .icon {
    color: if(@DARK_MODE, @colorText, @colorTextOpposite);
  }

  &.draft {
    background-color: transparent;
    &:hover {
      background-color: @backgroundButtonHovered;
    }
  }

  &:hover {
    opacity: 1;
  }
}

.toCorner () { 
  bottom: @footerHeight + @spaceMini;
  right: @spaceMini;
  left: unset;
}


.toOppositeCorner {
  right: unset;
  left: @spaceMini;
}


.draft-edit {
  .toCorner();
  position: absolute;
  color: @colorTextOpposite;
  z-index: 100;
  width: 20rem;
  height: 10rem;
  padding: @spaceHalf;
  background-color: @backgroundFooter;
  border-radius: 4px;
  textarea {
    padding: @spaceHalf;
    height: 100%;
    color: inherit;
    background-color: @primary;
    border: none;
    border-radius: 4px;

    &::placeholder {
      color: darken(@colorTextOpposite, 20%)
    }
  }
}


.unread-pin {
  font-size: .5rem;
  font-family: monospace;
  border-radius: 100%;
  background: red;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -0.75rem;
  margin-top: -0.75rem;
  font-weight: bold;
  animation: blink-animation .5s steps(18, start) infinite;
}

.scale-control, .locale-select, .notification-prefs {
  .toCorner();
  padding: @space;
  position: absolute;
  z-index: 100;
  background-color: @backgroundFooter;
  width: 20rem;
  color: @colorTextOpposite;
  border-radius: 4px;  
}

.locale-select {
  .lang-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon {
      color: @colorTextOpposite;
      font-size: 1rem;      
    }
  }
}

.rtl {
  .draft-edit, .scale-control, .locale-select {
    .toOppositeCorner();
  }
}