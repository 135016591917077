@prefixClass: rc-slider;

@disabled-color: #ccc;
@border-radius-base: 0px;
@primary-color: @primary;
@background-color: @background;
@rail-color: @borderColor;
@secondary-color: @secondary;
@tooltip-color: #fff;
@tooltip-bg: tint(#666, 4%);
@tooltip-arrow-width: 4px;
@tooltip-distance: @tooltip-arrow-width+4;
@tooltip-arrow-color: @tooltip-bg;
@ease-out-quint      : cubic-bezier(0.23, 1, 0.32, 1);
@ease-in-quint       : cubic-bezier(0.755, 0.05, 0.855, 0.06);

.borderBox() {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); //  remove tap highlight color for mobile safari

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); //  remove tap highlight color for mobile safari
  }
}

.@{prefixClass} {
  position: relative;
  height: @unit;
  display: flex;
  align-items: center;
  padding: @spaceMini 0;
  width: 100%;
  border-radius: @border-radius-base;
  touch-action: none;
  .borderBox();

  &-container {
    margin-inline-start: @space;
    margin-inline-end: @space;
  }

  &-rail {
    position: absolute;
    width: 100%;
    background-color: @rail-color;
    height: .2rem;
    border-radius: @border-radius-base;
  }

  &-track {
    position: absolute;
    left: 0;
    height: .2rem;
    border-radius: @border-radius-base;
    background-color: @primary-color;
  }

  &-handle {
    position: absolute;
    width: @space*2;
    height: 0.6rem;
    cursor: pointer;
    cursor: -webkit-grab;    
    cursor: grab;    
    background-color: @primary-color;
    touch-action: pan-x;

    &-dragging&-dragging&-dragging {
      border-color: tint(@secondary-color, 20%);
      box-shadow: 0 0 0 3px tint(@secondary-color, 50%);
    }

    &:focus {
      outline: none;
    }

    &:active {
      border-color: tint(@secondary-color, 20%);
      box-shadow: 0 0 0 3px tint(@secondary-color, 50%);
      cursor: -webkit-grabbing;
      cursor: grabbing;
    }
  }

  &-mark {
    position: absolute;
    top: 1.125rem;
    left: 0;
    width: 100%;
    font-size: 0.75rem;
  }

  &-mark-text {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: #999;

    &-active {
      color: #666;
    }
  }

  &-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
  }

  &-dot {
    position: absolute;
    bottom: -2px;
    margin-left: -4px;
    width: 8px;
    height: 8px;
    border: 2px solid #e9e9e9;
    background-color: @background-color;
    cursor: pointer;
    border-radius: 50%;
    vertical-align: middle;
    &-active {
      border-color: tint(@primary-color, 50%);
    }
    &-reverse {
      margin-inline-end: -4px;
    }
  }

  &-disabled {
    background-color: @background-color;

    .@{prefixClass}-track {
      background-color: @disabled-color;
    }

    .@{prefixClass}-handle, .@{prefixClass}-dot {
      border-color: @disabled-color;
      box-shadow: none;
      background-color: @background-color;
      cursor: not-allowed;
    }

    .@{prefixClass}-mark-text, .@{prefixClass}-dot {
      cursor: not-allowed!important;
    }
  }
}

.@{prefixClass}-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;

  .@{prefixClass} {
    &-rail {
      height: 100%;
      width: 4px;
    }

    &-track {
      left: 5px;
      bottom: 0;
      width: 4px;
    }

    &-handle {
      margin-left: -5px;
      touch-action: pan-y;
    }

    &-mark {
      top: 0;
      left: 18px;
      height: 100%;
    }

    &-step {
      height: 100%;
      width: 4px;
    }

    &-dot {
      left: 2px;
      margin-bottom: -4px;
      &:first-child {
        margin-bottom: -4px;
      }
      &:last-child {
        margin-bottom: -4px;
      }
    }
  }
}

.motion-common() {
  animation-duration: .3s;
  animation-fill-mode: both;
  display: block !important;
}

.make-motion(@className, @keyframeName) {
  .@{className}-enter, .@{className}-appear {
    .motion-common();
    animation-play-state: paused;
  }
  .@{className}-leave {
    .motion-common();
    animation-play-state: paused;
  }
  .@{className}-enter.@{className}-enter-active, .@{className}-appear.@{className}-appear-active {
    animation-name: ~"@{keyframeName}In";
    animation-play-state: running;
  }
  .@{className}-leave.@{className}-leave-active {
    animation-name: ~"@{keyframeName}Out";
    animation-play-state: running;
  }
}
.zoom-motion(@className, @keyframeName) {
  .make-motion(@className, @keyframeName);
  .@{className}-enter, .@{className}-appear {
    transform: scale(0, 0); // need this by yiminghe
    animation-timing-function: @ease-out-quint;
  }
  .@{className}-leave {
    animation-timing-function: @ease-in-quint;
  }
}
.zoom-motion(rc-slider-tooltip-zoom-down, rcSliderTooltipZoomDown);

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
}

@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
}

.@{prefixClass}-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;

  .borderBox();

  &-hidden {
    display: none;
  }

  &-placement-top {
    padding: @tooltip-arrow-width 0 @tooltip-distance 0;
  }

  &-inner {
    padding: 6px 2px;
    min-width: 24px;
    height: 24px;
    font-size: 12px;
    line-height: 1;
    color: @tooltip-color;
    text-align: center;
    text-decoration: none;
    background-color: @tooltip-bg;
    border-radius: @border-radius-base;
    box-shadow: 0 0 4px #d9d9d9;
  }

  &-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

  &-placement-top &-arrow {
    bottom: @tooltip-distance - @tooltip-arrow-width;
    left: 50%;
    margin-left: -@tooltip-arrow-width;
    border-width: @tooltip-arrow-width @tooltip-arrow-width 0;
    border-top-color: @tooltip-arrow-color;
  }
}